import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Hero from '../components/Hero/Hero';
import TextSection from '../components/TextSection/TextSection';

export default function Article({data}) {
  return (
    <Layout>
      <div className="page">
        <Hero
          className={'bg-secondary reset-h1'}
          text={data.post.data.article_title.html}
          image=''
        />
        <TextSection
          text={[data.post.data.article.html]}
        />

      </div>
    </Layout>
  )}

  export const Head = ({ data }) => (
    <SearchEngineOpt
      title={data.post.seo.title && data.post.seo.title.text}
      description={data.post.seo.description && data.post.seo.description.text}
      image={data.post.seo.image && data.post.seo.image.url}
      keywords={''} />
  )

export const postQuery = graphql`
  query Post($slug: String) {
    post: prismicArticle(id: { eq: $slug }) {
      id
    uid
    seo: data {
      title {
        text
      }
      description {
        text
      }
      image {
        url
      }
    }
      data {
        article_title {
          html
        }
        date {
          text
        }
        publisher {
            text
        }
        article {
          html
        }
      }
    }
  }
`
